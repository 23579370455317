<template>
  <div v-if="src" class="bg-blue-dark-500">
    <div
      ref="wrapper"
      class="relative size-full overflow-hidden transition-opacity duration-500"
      :class="[isReady ? 'opacity-100' : 'opacity-0']"
    >
      <div
        ref="playerEl"
        class="video-player plyr__video-embed size-full origin-center"
        :class="{
          ' !absolute !left-1/2 !top-1/2 translate-x-[-50%] translate-y-[-50%]':
            !keepAspectVideo && isReady,
        }"
        @ready="onReady($event)"
        @playing.once="onPlaying()"
        @ended="$emit('ended')"
      >
        <iframe
          :src="embedUrl"
          allowfullscreen
          allowtransparency
          allow="autoplay"
        />
      </div>

      <transition name="fade-slow">
        <div v-if="coverImage && showCoverImage" class="absolute inset-0">
          <!-- COVER IMAGE -->
          <AppImage
            class="size-full object-cover"
            :src="coverImage"
            loading="lazy"
            :alt="$i18n.locale === 'fr' ? coverImage.alt_fr : coverImage.alt"
          />

          <!-- PLAY BUTTON -->
          <button
            v-if="!playOnHover"
            :aria-label="$t('accessibility.play_video')"
            class="absolute left-1/2 top-1/2 flex size-12 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-white/30 transition-transform hover:scale-110"
            @click="play()"
          >
            <TriangleIcon class="w-4 text-white" />
          </button>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="!coverImage && !playingStarted" class="absolute inset-0">
          <!-- PLAY BUTTON WITHOUT COVER IMAGE -->
          <button
            :aria-label="$t('accessibility.play_video')"
            class="absolute left-1/2 top-1/2 flex size-12 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-white/30 transition-transform hover:scale-110"
            @click="play(), (playingStarted = true)"
          >
            <TriangleIcon class="w-4 text-white" />
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
// https://github.com/sampotts/plyr
import Plyr from 'plyr'
import 'plyr/dist/plyr.css'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const props = defineProps({
  src: { type: String, default: null },
  controls: { type: Array, default: () => [] },
  settings: { type: Array, default: () => [] },
  youtube: { type: Object, default: () => ({}) },
  autoplay: { type: Boolean, default: false },
  playOnHover: { type: Boolean, default: false },
  coverImage: { type: Object, default: null },
  keepAspectVideo: { type: Boolean, default: false },
  loadingLazy: { type: Boolean, default: false },
})

// we need this to reCalc the aspect ratio when new news gets loaded
const news = inject('news', null)

// -- EMITS --
const emit = defineEmits(['playing', 'ended'])

// -- VARS --
const player = ref(null)
const isReady = ref(false)
const playingStarted = ref(false)
const showCoverImage = ref(true)
let scrollTrigger = null

// -- REFS --
const wrapper = ref(null)
const playerEl = ref(null)

// -- COMPUTED --
const embedUrl = computed(() => {
  return useGetVideoEmbedUrl(props.src)
})

// -- FUNCTIONS --
function onResize() {
  // calculate the current aspect ratio
  if (!playerEl.value) return
  // calculate the current aspect ratio
  if (wrapper.value.offsetWidth / wrapper.value.offsetHeight > 16 / 9) {
    playerEl.value.style.height = wrapper.value.offsetWidth * (9 / 16) + 'px'
    playerEl.value.style.width = wrapper.value.offsetWidth + 'px'
  } else {
    playerEl.value.style.width = wrapper.value.offsetHeight / (9 / 16) + 'px'
    playerEl.value.style.height = wrapper.value.offsetHeight + 'px'
  }
}

async function onReady(event) {
  if (props.autoplay) {
    play()
    emit('playing')
  }

  if (props.playOnHover) {
    player.value.play()
  }

  const instance = event.detail.plyr

  // Check if it's a YouTube video
  if (instance.provider === 'youtube') {
    // Use the YouTube API to set the quality
    instance.embed.setPlaybackQuality('hd720')
  }

  setTimeout(() => (isReady.value = true), 200)
}

function onPlaying() {
  playingStarted.value = true
  if (props.playOnHover) {
    pause()
  }
}

function play() {
  showCoverImage.value = false
  player.value.play()
}

async function pause() {
  if (props.playOnHover) {
    showCoverImage.value = true
    // wait to display the cover image -> this hides the ugly youtube title
    await ((ms = 350) => new Promise((resolve) => setTimeout(resolve, ms)))(350)
  }

  player.value.pause()
}

const debouncedOnResize = useDebounceFn(onResize, 500)

// EXPOSES
defineExpose({
  play,
  pause,
})

// -- WATCHERS --
if (news) {
  watch(news, async () => {
    await nextTick()
    debouncedOnResize()
  })
}

// -- MOUNTED --
onMounted(() => {
  if (!props.keepAspectVideo) {
    window.addEventListener('resize', debouncedOnResize)
    onResize()
  }

  // check if has src
  if (!props.src) return

  if (props.loadingLazy) {
    scrollTrigger = ScrollTrigger.create({
      start: 0,
      end: 'max',
      onUpdate: updateValues,
    })
  } else {
    initPlayer()
  }
})

function updateValues() {
  if (ScrollTrigger.isInViewport(wrapper.value)) {
    initPlayer()
    scrollTrigger.kill()
  }
}

function initPlayer() {
  player.value = new Plyr(playerEl.value, {
    controls: props.controls,
    settings: props.settings,
    muted: true,
    playsinline: true,
    youtube: {
      noCookie: true,
      origin: window.location.origin,
      ...props.youtube,
    },
  })
}

onUnmounted(() => {
  if (!props.keepAspectVideo) {
    window.removeEventListener('resize', debouncedOnResize)
  }
})
</script>

<style lang="scss">
.video-player {
  .plyr,
  .plyr__video-wrapper {
    @apply h-full w-full;
  }
}

.plyr__video-wrapper iframe {
  @apply h-full w-full;
}
</style>
